var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PlayerV1Impl_inner;
export class PlayerV1Impl {
    constructor(inner) {
        _PlayerV1Impl_inner.set(this, void 0);
        __classPrivateFieldSet(this, _PlayerV1Impl_inner, inner, "f");
    }
    addFSCommandHandler(handler) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").addFSCommandHandler(handler);
    }
    get readyState() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f")._readyState;
    }
    get metadata() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").metadata;
    }
    get loadedConfig() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").loadedConfig ?? null;
    }
    async reload() {
        await __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").reload();
    }
    async load(options, isPolyfillElement = false) {
        await __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").load(options, isPolyfillElement);
    }
    resume() {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").play();
    }
    get isPlaying() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").isPlaying;
    }
    get volume() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").volume;
    }
    set volume(value) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").volume = value;
    }
    get fullscreenEnabled() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").fullscreenEnabled;
    }
    get isFullscreen() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").isFullscreen;
    }
    setFullscreen(isFull) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").setFullscreen(isFull);
    }
    requestFullscreen() {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").enterFullscreen();
    }
    exitFullscreen() {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").exitFullscreen();
    }
    async downloadSwf() {
        await __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").downloadSwf();
    }
    displayMessage(message) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").displayMessage(message);
    }
    suspend() {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").pause();
    }
    get suspended() {
        return !__classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").isPlaying;
    }
    set traceObserver(observer) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").traceObserver = observer;
    }
    get config() {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").config;
    }
    set config(value) {
        __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").config = value;
    }
    callExternalInterface(name, ...args) {
        return __classPrivateFieldGet(this, _PlayerV1Impl_inner, "f").callExternalInterface(name, args);
    }
}
_PlayerV1Impl_inner = new WeakMap();
